import React, { useEffect, useState } from 'react';
import {
	Button,
	Card,
	Col,
	Form,
	FormInstance,
	Input,
	InputNumber,
	message,
	Row,
	Select,
	Tooltip,
	TreeSelect
} from 'antd';
import Api from '../../../data/API';
import { getWuyeProjectInfo, updataWuyeProjectInfo } from '../../../services/FoundationSetupService';
import { getAllDeviceList, getDevices, getUserDevicesWithUseKeys0 } from '../../../services/ZhiNengMenJinService';
import DAL from '../../../data/DAL';
let QRCode = require('qrcode.react');
const ParameterSet: React.FC = () => {
	useEffect(() => {
		projectInfo();
		getDeviceList();
	}, []);
	const FormItem = Form.Item;
	const [projectName, setProjectName] = useState('');
	const [loading, setLoading] = useState(false);
	const [form] = Form.useForm<FormInstance>();
	const [devices, setdevices] = useState([]);
	const formLayout = {
		labelCol: { span: 10 },
		wrapperCol: { span: 14 },
	};
	// const renChildren = (list) => {
	// 	list.forEach((element) => {
	// 		if (element.value.includes(':::')) {
	// 			const value = element.value.split(":::")[1];
	// 			element.value =value ;
	// 		}
	// 		if (element.children != undefined && element.children.length > 0) {
	// 			renChildren(element.children);
	// 		}
	// 	});
	// };
	const projectInfo = async () => {
		const res = await getWuyeProjectInfo({ wuyeUuid: localStorage.getItem('wyUuid') });
		console.log('信息', res);
		if (res.err === 0) {
			const data = res.data;
			setProjectName(data.name);
			const formData: any = {
				name: data.name,
				address: data.address,
				contactName: data.contactName,
				contactPhone: data.contactPhone,
				contactEmail: data.contactEmail,
				huiYiShiTuiKuanShiJian:data.huiYiShiTuiKuanShiJian,
				batchAddEmpDevice: DAL.dal.isNotNull(data.batchAddEmpDevice)
					? data.batchAddEmpDevice.split(',')
					: [],
				orderNoticePhone: DAL.dal.isNotNull(data.orderNoticePhone)
					? data.orderNoticePhone.split(',')
					: [],
			};
			form.setFieldsValue(formData);
		}
	};
	const downloadQR = (ev: any) => {
		const canvas: any = ev.target;
		console.log('二维码' + canvas);
		let link = document.createElement('a');
		link.textContent = 'download image';
		link.setAttribute('href', canvas.toDataURL());
		link.setAttribute('download', ev.target.id + '.png');
		link.style.visibility = 'hidden';
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	};
	const getDeviceList = async () => {
		let res = await getAllDeviceList({});
		if (res.err === 0) {
			setdevices(res.data || []);
		}
	};
	const updataInfo = () => {
		form.validateFields().then(async (data: any) => {
			setLoading(true);
			const formData: any = {
				wuyeUuid: localStorage.getItem('wyUuid'),
				projectName: data.name,
				address: data.address,
				contactName: data.contactName,
				contactPhone: data.contactPhone,
				contactEmail: data.contactEmail,
				huiYiShiTuiKuanShiJian: data.huiYiShiTuiKuanShiJian,
				batchAddEmpDevice: data.batchAddEmpDevice != undefined ? data.batchAddEmpDevice.toString() : '',
				orderNoticePhone:  data.orderNoticePhone != undefined ? data.orderNoticePhone.toString() : ''
			};

			const res = await updataWuyeProjectInfo(formData);
			if (res.err === 0) {
				message.success('保存成功');
			} else {
				message.error('保存失败');
			}
			setLoading(false);
		});
	};
	return (
		<>
			<div style={{ backgroundColor: '#fff', paddingBottom: 20, paddingTop: 20 }}>
				<Row>
					<Col span={12}>
						<Form key={devices.toString()} form={form} {...formLayout}>
							<FormItem
								label={'项目名称'}
								tooltip={'该名称将展示在APP和小程序端'}
								name={'name'}
								rules={[{ required: true }]}
							>
								<Input />
							</FormItem>
							<FormItem label={'项目地址'} name={'address'}>
								<Input />
							</FormItem>
							<FormItem label={'项目联系人'} name={'contactName'}>
								<Input />
							</FormItem>
							<FormItem label={'项目联系人电话'} name={'contactPhone'}>
								<Input />
							</FormItem>
							<FormItem label={'项目联系人邮箱'} name={'contactEmail'}>
								<Input />
							</FormItem>
							<FormItem label={'批量添加员工设备'} name={'batchAddEmpDevice'}>
								<Select
									mode="multiple"
									showSearch
									optionFilterProp="children"
								>
									{devices.map((item) => {
										return (
											<>
												<Select.Option value={item.device_id}>{item.description}</Select.Option>
											</>
										);
									})}
								</Select>
							</FormItem>
							<FormItem label={'会议室自动退款时限(分钟)'} name={'huiYiShiTuiKuanShiJian'}>
								<InputNumber style={{width: '100%'}} min={10} />

							</FormItem>
							<FormItem label={'订单通知人'} name={'orderNoticePhone'}>
								<Select mode={"tags"} style={{width: '100%'}} />

							</FormItem>
						</Form>
						<Row justify={'start'} style={{ alignItems: 'center' }}>
							<Col span={6}></Col>
							<Col span={5}>
								<Button type="primary" onClick={updataInfo} loading={loading}>
									保存
								</Button>
							</Col>
						</Row>
					</Col>
				</Row>
			</div>
			<Card hidden={true} title={'二维码下载'} style={{ width: '100%' }}>
				<Card.Grid style={{ width: '25%' }}>
					<Row align={'middle'}>
						<Col>两点社区小程序</Col>
					</Row>
					<Row align={'middle'} style={{ marginTop: 10 }}>
						<Tooltip title={'双击点击下载该二维码'}>
							<QRCode
								onDoubleClick={(e: any) => {
									downloadQR(e);
								}}
								style={{ width: '80%', height: 'auto' }}
								value={
									'https://api.guosim.com/redirect?wuyeproj_id=' +
									Api.wuyeUuid +
									'&page_id=addCommunity&params=' +
									projectName
								}
							/>
						</Tooltip>
					</Row>
				</Card.Grid>
			</Card>
		</>
	);
};
export default ParameterSet;
